import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { ComplexSearchBar } from "../../../components/ui/ComplexSearchBar/ComplexSearchBar";
import { CrmSearch } from "../../../components/ui/CrmSearch/CrmSearch";
import {
  CrmSearchCategorySelect,
  defaultQueryCategoryOption
} from "../../../components/ui/CrmSearchCategorySelect/CrmSearchCategorySelect";
import useDynamicSearchPlaceholder from "../../../utils/useDynamicSearchPlaceholder";
import { useSearchCategoriesOptions } from "../../../utils/useGetSearchCategoriesOptions";
import styles from './filter.module.scss';
import { Col, Row } from 'react-bootstrap';
import DatePickerRange from 'components/ui/DatePickerRange/DatePickerRange';
import FormInput from 'components/ui/FormInput';
import Button from 'components/ui/Button';
import { ArrowsClockwise } from 'phosphor-react';
import { useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';
import 'react-toggle/style.css';
import './reload-button.scss';
import JokerSelect from 'components/ui/JokerSelect';
import JokerMultiSelect from 'components/ui/JokerMultiSelect/JokerMultiSelect';
import { getTransactionsTypeMethods, getTransactionsTypesList } from 'api/payments';
import PaginateSelect from '../../../components/ui/PaginateSelect';
import {
  fetchDesks,
  fetchOperators,
  fetchTeams,
} from '../../../utils/managment/fetchData';
import { CURRENCY_OPTIONS } from '../../../constants/clients/filters.const';
import { getPartnersList } from '../../../api/partners';
import { PaymentFilter } from 'models/Payments/Filter';
import { getFilterList } from "../../../api/filters";
import SaveFilterPopup from "../PaymentsPopups/SaveFilterPopup";
import ButtonReset from "../../../components/ui/Button/ButtonReset.tsx";
import DeleteFilterPopup from 'pages/Clients/SearchClients/SearchClientsPopups/DeleteFilterPopup/DeleteFilterPopup.tsx';
import FilterSetSelectPayments from "../../../components/ui/FilterSetSelectPayments/FilterSetSelectPayments.tsx";
import { optionsFirstTimeDeposit, optionsTransactionStatus } from "../../../constants/payments/payments.const.ts";
import { getNumberRange } from "../../../utils/getNumberRange.ts";
import { clearEmptyFilter } from "../../../utils/clearEmptyFilter.ts";

const cx = classNames.bind(styles);


type Props = {
  onSubmit: (data: PaymentFilter) => void;
  reloadFilters: boolean;
  queryMessage: string,
}

const PaymentsFilter = (props: Props) => {
  const { onSubmit, reloadFilters, queryMessage} = props;

  const [filterSet, setFilterSet] = useState([]);
  const [paymentMethodOpt, setPaymentMethodOpt] = useState([]);
  const [transactionTypeOpt, setTransactionTypeOpt] = useState([]);
  const [affiliate, setAffiliate] = useState([]);
  const paymentsSearchOptions = useSearchCategoriesOptions('depositTransaction');

  const componentMounted = useRef(false);

  const getDefaultValue = () => {
    return {
      query: '',
      query_category: defaultQueryCategoryOption,
      payment_method: [],
      payment_type: [],
      transaction_type: [],
      transaction_status: [],
      status_updated_at: '',
      deskIds: [],
      teamIds: [],
      operatorIds: [],
      affiliateIds: [],
      currencyIds: [],
      accountType: { value: 'any', label: 'Any' },
      first_time_deposit: { value: 'any', label: 'Any' },
      amount: {
        from: '',
        to: '',
      },
      created_at: '',
      custom_filters: '',
    };
  };

  const { handleSubmit, reset, watch, setValue, getValues, control, formState } = useForm({
    reValidateMode: 'onChange',
    defaultValues: getDefaultValue(),
  });
  const { dirtyFields } = formState;

  const dynamicSearchPlaceholder = useDynamicSearchPlaceholder(watch, getValues, paymentsSearchOptions);

  const prepareFiltersData = (data) => {
    const postData = {
      filter: {
        payment_method: getValue(data['payment_method']),
        payment_type: getValue(data['payment_type']),
        transaction_type: getValue(data['transaction_type']),
        transaction_status: getValue(data['transaction_status']),
        status_updated_at: data['status_updated_at'],
        deskIds: getValue(data['deskIds']),
        teamIds: getValue(data['teamIds']),
        operatorIds: getValue(data['operatorIds']),
        affiliateIds: getValue(data['affiliateIds']),
        currencyIds: getValue(data['currencyIds']),
        accountType: getValue(data['accountType']) ? [getValue(data['accountType'])] : null,
        first_time_deposit: getValue(data['first_time_deposit']),
        amount: getNumberRange(data['amount']),
        created_at: data['created_at'],
      },
    };

    if (data['query']) {
      postData['query'] = data['query'];
    }

    if (data['query_category']) {
      postData['searchCategory'] = data['query_category']?.value;
    }

    return postData;
  };

  const submit = (data) => {
    const prepeared = prepareFiltersData(data);
    const postData = {
      ...prepeared,
      filter: clearEmptyFilter(prepeared.filter),
    };

    onSubmit(postData);
  };

  const resetForm = () => {
    onSubmit(null);
    reset(getDefaultValue());
  };

  const fetchFilterSet = () => {
    getFilterList('type=payment&per_page=3000')
      .then((res) => {
        if (componentMounted.current) {
          setFilterSet(res?.data?.data);
        }
      })
      .catch(console.log);
  };

  useEffect(() => {
    componentMounted.current = true;

    fetchFilterSet();
    fetchTransactionsTypesList();
    fetchPaymentsMethodList();
    fetchAffiliateList();

    return () => {
      componentMounted.current = false;
    };
  }, []);

  const fetchPaymentsMethodList = () => {
    getTransactionsTypeMethods().then((res) => {
      if (res && res.status === 200) {
        const opt = res.data.payment_filter.map((opt) => ({
          value: opt,
          label: opt,
        }));
        setPaymentMethodOpt(opt);
      }
    });
  };
  const fetchTransactionsTypesList = () => {
    getTransactionsTypesList().then((res) => {
      if (res && res.status === 200) {
        const opt = res.data.map((opt) => ({
          value: opt,
          label: opt,
        }));
        setTransactionTypeOpt(opt);
      }
    });
  };

  const fetchAffiliateList = () => {
    getPartnersList('per_page=100')
      .then((res) => {
        if (componentMounted.current) {
          const opt = res.data.data.map(opt => ({
            value: opt.id,
            label: `${opt.first_name} ${opt && opt.last_name !== null ? opt.last_name : ''}`,
          }));
          setAffiliate([{ value: 0, label: 'No affiliate' }, ...opt]);
        }
      })
      .catch(console.log)
  };

  const getValue = (data) => {
    if (!data || (Array.isArray(data) && !data.length)) {
      return;
    }

    if (Array.isArray(data) && data.length) {
      const temp = data.map((item) => (item.value !== 'any' ? item.value : null));
      return temp.filter((item) => item || typeof item === 'number');
    }

    return data.value !== 'any' ? data.value : null;
  };

  const handleChange = (data): void => {
    const vals = getDefaultValue();

    if (!data) {
      return;
    }


    if (data.filter) {
      for (const key of Object.keys(data.filter)) {
        vals[key] = data.filter[key];
      }
    }
    vals['custom_filters'] = getValues('custom_filters');
    reset(vals, { keepDirty: true });
    submit(vals)
  };
  const getDirtyFieldsLength = () => Object.keys(dirtyFields).length;

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(submit);
        }}
        className={cx('filters')}
      >
        <Row>
          <Col md={12}>
            <ComplexSearchBar message={reloadFilters ? '' : queryMessage}>
              <>
                <CrmSearchCategorySelect
                  id='query_category'
                  name='query_category'
                  control={control}
                  options={paymentsSearchOptions}
                  onSelectChange={() => {
                      const { query } = getValues();
                    if (query) submit(getValues());
                  }}
                />
                <CrmSearch
                  id='query'
                  name='query'
                  control={control}
                  placeholder={dynamicSearchPlaceholder}
                  onSearch={() => submit(getValues())}
                  handleChange={(value: string) => {
                      if (value?.length === 0) submit(getValues());
                  }}
                />
              </>
            </ComplexSearchBar>
          </Col>
        </Row>
        <div className={cx('filters-sets')}>
          <FilterSetSelectPayments
            label='Customize filters'
            control={control}
            id='custom_filters'
            name='custom_filters'
            placeholder={'--Select--'}
            options={filterSet}
            updateList={fetchFilterSet}
            onSelectChange={handleChange}
          />
        </div>
        <Row>
          <Col xl={3} lg={4} md={6}>
            <JokerMultiSelect
              label="Payment method"
              control={control}
              id="payment_method"
              name="payment_method"
              options={paymentMethodOpt}
            />
          </Col>
          <Col xl={3} lg={4} md={6}>
            <JokerMultiSelect
              label="Transaction type"
              control={control}
              id="transaction_type"
              name="transaction_type"
              options={transactionTypeOpt}
            />
          </Col>
          <Col xl={3} lg={4} md={6}>
            <JokerMultiSelect
              label="Transaction status"
              control={control}
              id="transaction_status"
              name="transaction_status"
              options={optionsTransactionStatus}
            />
          </Col>
          <Col xl={3} lg={4} md={6}>
            <DatePickerRange
              name="status_updated_at"
              control={control}
              label="Status modification date range"
              placeholder="From - To"
            />
          </Col>
          <Col xl={3} lg={4} md={6}>
            <PaginateSelect
              isMulti={true}
              label="Desks"
              placeholder={'Any'}
              control={control}
              id="desks"
              name="deskIds"
              onChange={(page, search) => fetchDesks(page, search)}
            />
          </Col>
          <Col xl={3} lg={4} md={6}>
            <PaginateSelect
              isMulti={true}
              label="Teams"
              control={control}
              placeholder={'Any'}
              id="teams"
              name="teamIds"
              onChange={(page, search) => fetchTeams(page, search)}
            />
          </Col>
          <Col xl lg={4} md={4}>
            <PaginateSelect
              isMulti={true}
              placeholder={'Any'}
              label="Operator"
              control={control}
              id="operatorIds"
              name="operatorIds"
              onChange={(page, search) => fetchOperators(page, search)}
            />
          </Col>
          <Col xl lg={4} md={4}>
            <JokerMultiSelect
              label='Affiliates'
              control={control}
              id='affiliate'
              name='affiliateIds'
              options={affiliate}
            />
          </Col>
          <Col xl={3} lg={4} md={6}>
            <JokerMultiSelect
              label="Currency"
              control={control}
              id="currencyIds"
              name="currencyIds"
              options={CURRENCY_OPTIONS}
            />
          </Col>
          <Col xl={3} lg={4} md={6}>
            <JokerSelect
              label="First time deposit"
              control={control}
              id="first_time_deposit"
              name="first_time_deposit"
              options={optionsFirstTimeDeposit}
            />
          </Col>
          <Col xl={3} lg={4} md={6}>
            <Row className={cx('range-wrapper')}>
              <Col md={6}>
                <FormInput
                  name="amount.from"
                  type="number"
                  placeholder="0.00"
                  min={0}
                  label="Amount"
                  control={control}
                />

                <span className={cx('range-divider')}>-</span>
              </Col>
              <Col md={6}>
                <FormInput
                  name="amount.to"
                  min={0}
                  type="number"
                  label="&nbsp;"
                  placeholder="0.00"
                  control={control}
                />
              </Col>
            </Row>
          </Col>
          <Col xl={3} lg={4} md={6}>
            <DatePickerRange
              name="created_at"
              control={control}
              label="Creation date range"
              placeholder="From - To"
            />
          </Col>
        </Row>
      </form>

      <Row>
        <Col md={12}>
          <div className={cx('filters__btns')}>
            {!isEmpty(getValues('custom_filters')) ?
              <DeleteFilterPopup
                triggerBtn={<ButtonReset
                  buttonText='Delete'
                  buttonType='button'
                  style={{ maxWidth: 70, justifyContent: 'center' }}
                  onClick={null}
                />}
                prepareFiltersData={() => {
                  return { ...clearEmptyFilter(getValues()) };
                }}
                onCloseModal={() => {
                  fetchFilterSet();
                  resetForm();
                }}
              /> : null}
            {!isEmpty(getValues('custom_filters')) || getDirtyFieldsLength() ?
              <SaveFilterPopup
                triggerBtn={
                  <ButtonReset
                    buttonText={!isEmpty(getValues('custom_filters')) ? 'Update' : 'Save'}
                    style={{ maxWidth: 70, justifyContent: 'center' }}
                    onClick={null}
                  />
                }
                filterSet={filterSet}
                isUpdate={!isEmpty(getValues('custom_filters'))}
                onCloseModal={() => {
                  fetchFilterSet();
                  submit(getValues());
                }}
                prepareFiltersData={() => {
                  return { ...clearEmptyFilter(getValues()) };
                }}
                type='payment'
              />
              : null
            }
            <Button
              className={reloadFilters ? 'reload-btn reload' : 'reload-btn'}
              icon={<ArrowsClockwise color="var(--white)" size={18} />}
              onClick={(e) => {
                e.preventDefault();
                submit(getValues());
              }}
            />
            <Button
              buttonText="Reset"
              type="reset"
              onClick={() => resetForm()}
              // disabled={!getDirtyFieldsLength()}
            />
            <Button
              buttonText="Apply"
              type="submit"
              onClick={handleSubmit(submit)}
              disabled={!getDirtyFieldsLength()}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default PaymentsFilter;
